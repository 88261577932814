import React from "react";
import "./TermsAndConditions.css";
import { Link } from "react-router-dom";
import Logo from "./Logo";

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <Logo />

      <h1 className="terms-title">Terms and Conditions</h1>
      <p className="terms-date">Effective Date: 21 October 2024</p>

      <h2 className="terms-heading">1. Definitions</h2>
      <p className="terms-text">
        <strong>Admin:</strong> The restaurant’s designated personnel with
        access to manage orders, users, and restaurant details.
        <br />
        <strong>User:</strong> Any person using the Hungry Greek app to place
        orders, redeem offers, or engage with restaurant services.
        <br />
        <strong>App:</strong> The Hungry Greek mobile application.
      </p>

      <h2 className="terms-heading">2. User Accounts</h2>
      <p className="terms-text">
        Users must be 18 years of age or older to use the Hungry Greek website
        and app. Any individual under 18 is not permitted to create an account
        or use the website's and app’s services. Users are responsible for
        maintaining the confidentiality of their login credentials.
      </p>

      <h2 className="terms-heading">3. Admin Responsibilities</h2>
      <p className="terms-text">
        Admins can manage and approve orders, update menu items, view user data,
        and manage special offers through the Hungry Greek admin panel.
      </p>

      <h2 className="terms-heading">4. User Responsibilities</h2>
      <p className="terms-text">
        Users can place and track orders, redeem offers, and manage their
        personal profile information.
      </p>

      <h2 className="terms-heading">5. Orders and Payments</h2>
      <p className="terms-text">
        All orders must be paid through the app's secure payment gateway. Admins
        reserve the right to decline unconfirmed or faulty orders.
      </p>

      <h2 className="terms-heading">6. Special Offers and Loyalty Program</h2>
      <p className="terms-text">
        Special offers and loyalty points may be earned based on user
        activities. Admins manage these offers and can remove expired or fully
        redeemed ones.
      </p>

      <h2 className="terms-heading">7. Privacy and Data Protection</h2>
      <p className="terms-text">
        All personal data is processed in line with GDPR. Users can request data
        deletion or access through the app’s profile section.
      </p>

      <h2 className="terms-heading">8. Limitation of Liability</h2>
      <p className="terms-text">
        Hungry Greek is not responsible for technical issues or losses incurred
        through the use of the app.
      </p>

      <h2 className="terms-heading">9. Amendments</h2>
      <p className="terms-text">
        These terms may be updated periodically. Continued use of the app after
        updates means acceptance of the new terms.
      </p>

      <h2 className="terms-heading">10. Governing Law</h2>
      <p className="terms-text">
        These Terms and Conditions are governed by the laws of the United
        Kingdom.
      </p>
      <div className="terms-footer">
        <p className="terms-text">
          For more information on how we handle your data, please read our{" "}
          <Link to="/privacy-policy" className="terms-link">
            Privacy Policy
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
