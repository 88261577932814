/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "./styles/login.css";
import axios from "axios";

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function ResetPassword() {
  const query = useQuery();
  const [newPassword, setNewPassword] = useState("");
  const [newConfPassword, setNewConfPassword] = useState("");
  const [similar, setSimilar] = useState(true);
  const [canLogin, setCanLogin] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const navigator = useNavigate();

  useEffect(() => {
    checkSimilar();
  }, [newPassword, newConfPassword]);

  const checkSimilar = () => {
    if (newPassword === newConfPassword) {
      setSimilar(true);
    } else {
      setSimilar(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setErrorMsg("");
    if (similar) {
      try {
        await axios.post(
          "https://hq-control.hungrygreek.co.uk/api/v1/auth/reset-password",
          {
            password: newPassword,
            token: query.get("token"),
            email: query.get("email"),
          }
        );
        setNewConfPassword("");
        setNewPassword("");
        setCanLogin(true);
        setLoading(false);
      } catch (error) {
        setErrorMsg(
          error.response?.data?.msg ? error.response?.data?.msg : error
        );
        setCanLogin(false);
        setLoading(false);
        console.log(error);
      }
    } else {
      setErrorMsg("Please type the same password");
      setLoading(false);
    }
  };

  return (
    <article className="loginMainArticle">
      <article
        // style={{ height: `100hv-80px` }}
        className={"loginArticle loginImage"}
      >
        {canLogin && <h1 className="CheckEmail">Your password has changed!</h1>}
        <div className="divChangePass" style={{ position: "relative" }}>
          {loading ? (
            <>
              <LoadingSpinner />
              <h5 className="errorMsg">Please wait...</h5>
            </>
          ) : (
            <h5 className="errorMsg">{errorMsg}</h5>
          )}
          <h3>Change Password:</h3>
          <div className="divChangePassIn">
            <label>New Password</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => {
                setLoading(false);
                setErrorMsg("");
                setNewPassword(e.target.value);
              }}
              className={similar ? "" : "notSimilarPass"}
            />
            <label>Confirm New Password</label>
            <input
              type="password"
              value={newConfPassword}
              onChange={(e) => {
                setLoading(false);
                setErrorMsg("");
                setNewConfPassword(e.target.value);
              }}
              className={similar ? "" : "notSimilarPass"}
            />
            {!canLogin ? (
              <div>
                <button onClick={handleSubmit}>Submit</button>
                <br />
                <br />
                <button
                  className="BackTiLogInBtn"
                  style={{ backgroundColor: "#700" }}
                  onClick={() => {
                    navigator("/");
                  }}
                >
                  Back Home
                </button>
              </div>
            ) : (
              <button
                className="BackTiLogInBtn"
                onClick={() => {
                  navigator("/");
                }}
              >
                Back Home
              </button>
            )}
          </div>
        </div>
      </article>
    </article>
  );
}

export default ResetPassword;
