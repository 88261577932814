/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import "./styles/login.css";
import errImg from "./Images/errImg.png";
import doneImg from "./Images/done-Img.png";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import axios from "axios";
import "./styles/VerifyPage.css";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function VerifyEmail() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const query = useQuery();

  const verifyToken = async () => {
    setLoading(true);
    try {
      await axios.post(
        "https://hq-control.hungrygreek.co.uk/api/v1/auth/verify-email",
        {
          verificationToken: query.get("token"),
          email: query.get("email"),
        }
      );
    } catch (error) {
      // console.log(error.response);
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    verifyToken();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <>
        <div className="Verify-page">
          <img
            src={errImg}
            alt="error image"
            style={{ height: 200, width: 200 }}
            className="veriry-page-image"
          />
          <h4>
            There was an error, please double check your verification link
          </h4>
        </div>
        <Link to="/" className="btn">
          Back Home
        </Link>
      </>
    );
  }

  return (
    <>
      <div className="Verify-page">
        <img
          src={doneImg}
          alt="error image"
          className="veriry-page-image"
          style={{ height: 200, width: 200 }}
        />
        <h2>Account Confirmed</h2>
        <Link to="/" className="btn">
          Back Home
        </Link>
      </div>
    </>
  );
}

export default VerifyEmail;
