import React, { useEffect, useState } from "react";
import "./styles/home.css";
import Navigator from "../../Utils/Navigator";
import Intro from "./Intro";
import Menu from "./Menu";
import Contact from "./Contact";
import AboutUs from "./AboutUs";
import Footer from "./Footer";
import FoodMenu from "../../Utils/Menu/FoodMenu";
import { useGlobalContext } from "../../Content/Content";
import LegalLinks from "./LegalLinks";
// import Closed from "../Closed/Closed";

function Home() {
  const { openModal } = useGlobalContext();
  // const [closed, setClosed] = useState(true);

  // useEffect(() => {
  //   const today = new Date();
  //   const reopenDate = new Date("2024-08-22");

  //   // Check if today's date is before the reopening date
  //   if (today >= reopenDate) {
  //     setClosed(false);
  //   }
  // }, []);

  return (
    <article className="homeStyle">
      {/* {closed && <Closed />} */}
      <Navigator />
      {openModal && <FoodMenu />}
      <Intro />
      <Menu />
      <Contact />
      <AboutUs />
      <Footer />
      <LegalLinks />
    </article>
  );
}

export default Home;
