import React from "react";
import logo from "../../images/HUNGRY_GREEK-logo.png";
import { useNavigate } from "react-router-dom";

function Logo() {
  const navigate = useNavigate();
  return (
    <nav className="navigator">
      <img
        src={logo}
        alt="Hungry Greek Logo"
        className="logo"
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          navigate("/");
        }}
      />
    </nav>
  );
}

export default Logo;
