import React from "react";
import "./styles/LegalLinks.css";
import ServeSavvyLogo from "../../images/ServeSavvyLogo.png";

const LegalLinks = () => {
  return (
    <div className="legal-section">
      <div className="legal-links">
        <a href="/terms" className="legal-link">
          Terms and Conditions
        </a>
        <a href="/privacy-policy" className="legal-link">
          Privacy Policy
        </a>
      </div>

      <div className="copyright-section">
        <p>
          © {new Date().getFullYear()} Hungry Greek. All rights reserved for the
          content and branding on this site.
        </p>
        <p>
          Powered by{" "}
          <a
            href="https://servesavvy.co.uk/"
            className="serve-savvy-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ServeSavvyLogo}
              alt="ServeSavvy Logo"
              className="serve-savvy-logo"
            />{" "}
            ServeSavvy
          </a>
        </p>
      </div>
    </div>
  );
};

export default LegalLinks;
