import React from "react";
import "./PrivacyPolicy.css";
import { Link } from "react-router-dom";
import Logo from "./Logo";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <Logo />
      <h1 className="privacy-title">Privacy Policy</h1>
      <p className="privacy-date">Effective Date: 21 October 2024</p>

      <h2 className="privacy-heading">1. Information We Collect</h2>
      <p className="privacy-text">
        We collect personal data such as account information, order details, and
        usage data to provide better service through the Hungry Greek app.
      </p>

      <h2 className="privacy-heading">2. How We Use Your Information</h2>
      <p className="privacy-text">
        Your data is used to process orders, send notifications, manage offers,
        and improve the app's functionality.
      </p>

      <h2 className="privacy-heading">3. Legal Basis for Processing Data</h2>
      <p className="privacy-text">
        We process your data to fulfill contracts, meet legal obligations, and
        enhance your experience with the Hungry Greek app.
      </p>

      <h2 className="privacy-heading">4. Sharing Your Information</h2>
      <p className="privacy-text">
        We only share data with trusted service providers and to comply with
        legal requirements.
      </p>

      <h2 className="privacy-heading">5. Data Retention</h2>
      <p className="privacy-text">
        We retain your data as long as necessary to provide our services or as
        required by law.
      </p>

      <h2 className="privacy-heading">6. User Rights under GDPR</h2>
      <p className="privacy-text">
        You have the right to access, rectify, and delete your personal data at
        any time, in compliance with GDPR.
      </p>

      <h2 className="privacy-heading">7. Data Security</h2>
      <p className="privacy-text">
        We implement strong security measures to protect your data from
        unauthorized access or loss.
      </p>

      <h2 className="privacy-heading">8. Children’s Privacy</h2>
      <p className="privacy-text">
        Hungry Greek is not intended for users under the age of 16. We do not
        knowingly collect data from children.
      </p>

      <h2 className="privacy-heading">9. Changes to This Policy</h2>
      <p className="privacy-text">
        We may update this policy periodically. Continued use of the app implies
        acceptance of the updated policy.
      </p>

      <h2 className="privacy-heading">10. Contact Us</h2>
      <p className="privacy-text">
        For any privacy concerns, contact us at:{" "}
        <strong>hungrygreek.info@gmail.com</strong>.
      </p>

      <div className="terms-footer">
        <p className="terms-text">
          For more information about Terms and Conditions, please read our{" "}
          <Link to="/terms" className="terms-link">
            Terms and Condition
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
